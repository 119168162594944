<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card border-0 shadow">
          <div class="card-body">
            <h3 class="card-title mb-4">Educational Rooms & Webinars</h3>
            <p class="alert alert-success mb-4">
              Webinars, recorded educational courses, community interactions and
              special guest hosts are available to consume live, after the fact
              on desktop and on mobile.
            </p>

            <div class="row">
              <div class="col-md-4 mb-4" v-for="r in rooms" :key="r.id">
                <div class="card border-0 bg-light text-dark shadow h-100">
                  <i
                    class="far fa-star fa-2x text-primary"
                    style="position: absolute; top: 0.5rem; right: 0.5rem"
                    v-show="r.package_constraint === 'platinum'"
                    v-tooltip.bottom="'Platinum Access'"
                  ></i>
                  <img
                    :src="r.cover_image"
                    :alt="r.title"
                    class="card-img-top"
                    height="265"
                  />
                  <div class="card-body">
                    <h5 class="card-title text-dark fw-bold">
                      {{ r.title }}
                    </h5>
                  </div>
                  <div class="card-footer bg-white text-dark">
                    <div class="row">
                      <div class="col my-auto">
                        <h5 class="mb-0">
                          <span class="badge badge-pill bg-info"
                            >Media Events: {{ r.media.length }}</span
                          >
                        </h5>
                      </div>
                      <div class="col-auto ms-auto my-auto">
                        <button
                          class="btn btn-outline-primary"
                          @click="viewRoom(r.id)"
                        >
                          View Room
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["apiUrl"],
  data() {
    return {
      rooms: [],
    };
  },
  methods: {
    viewRoom(id) {
      this.$router.push("/webinars/" + id);
    },
    fetchRooms() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/edu/rooms")
        .then(({ data }) => {
          this.rooms = data.sort((a, b) => {
            return b.id - a.id
          });
        });
    },
  },
  created() {
    this.fetchRooms();
  },
  filters: {
    formatDateTime(date) {
      return moment.utc(date).local().format("YYYY-MM-DD HH:mm");
    },
  },
};
</script>

<style>
</style>
